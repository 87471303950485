.congrats-text {
  font-size: 3rem;
  font-weight: bold;
  color: #28a745;
  animation: animateText 1s ease-in-out infinite;
}

@keyframes animateText {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
