@font-face {
  font-family: "EastmanGrotesque";
  src: local("EastmanGrotesque"),
    url("./fonts/eastman-grotesque-bd.ttf") format("truetype");
}

@font-face {
  font-family: "MoreSugar";
  src: local("MoreSugar"),
    url("./fonts/MoreSugar-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "EastmanGrotesque";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #addcff;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
